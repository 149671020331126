<template>
  <div class="share-order-card" v-if="info.order_item">
    <div class="row1">
      <div class="left-wrap">订单号：{{ info.order_no }}</div>
      <div class="right-wrap">
        {{
          info.pay_status === 'PAIED' || info.pay_status === 'REFUNDED'
            ? `${info.logistic_status_description}(${info.pay_status_description})`
            : `${info.pay_status_description}`
        }}
      </div>
    </div>
    <van-divider class="divider-margin" />
    <div class="row2">
      <div class="left-img-wrap">
        <van-image
          width="50"
          height="50"
          :src="info.order_item.snap_good_order_item_img"
        />
      </div>
      <div class="mid-content">
        <div class="title">{{ info.order_item.snap_good_title || '-' }}</div>
        <div class="unit">
          规格：{{ info.order_item.snap_good_spec || '-' }}/{{
            info.order_item.snap_good_unit || '-'
          }}
        </div>
      </div>
      <div class="right-content">
        <div class="price1">￥{{ info.total_member_price_yuan || '-' }}</div>
        <div>
          X{{ info.order_item.count || 0
          }}{{ info.order_item.snap_good_unit || '-' }}
        </div>
      </div>
    </div>
    <div class="row3">
      <div class="left-wrap">
        <div>买家：{{ info.consignee_name || '-' }}</div>
        <div>下单时间：{{ info.create_time || '-' }}</div>
        <div>物流单号：{{ info.express_no || '-' }}</div>
      </div>
      <div class="right-wrap">
        <van-button size="small" plain color="#888" @click="shareGood">
          分享订单详情
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Image, Button, Divider } from 'vant'
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    shareGood() {
      this.$emit('share')
    }
  },
  components: {
    [Image.name]: Image,
    [Button.name]: Button,
    [Divider.name]: Divider
  },
  name: 'orderCard'
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';
.share-order-card {
  align-items: center;
  background: #fff;
  padding: 6px;
  box-sizing: border-box;
  font-size: 12px;
  margin-bottom: 6px;
  &:first-of-type {
    margin-top: 6px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
  .row1,
  .row3 {
    display: flex;
    box-sizing: border-box;
    .left-wrap {
      flex: 1;
    }
    .right-wrap {
      flex-shrink: 1;
    }
  }
  .divider-margin {
    margin: 6px 0 0 0;
  }
  .row2 {
    display: flex;
    padding: 6px 0;
    box-sizing: border-box;
    .mid-content {
      flex: 1;
      overflow: hidden;
      padding: 8px;
      flex-shrink: 1;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price1 {
        font-size: 9px;
        text-decoration: line-through;
      }
      .price2 {
        color: @red;
      }
    }
    .right-content {
      flex-shrink: 1;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
    }
  }

  .share-btn {
    min-width: 70px;
    margin-bottom: 20px;
  }
}
</style>
