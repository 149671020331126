<template>
  <div class="container">
    <!-- <van-nav-bar :safe-area-inset-top="true" :fixed="true" :title="active" /> -->
    <div class="content">
      <template v-if="activeArr[0] === active">
        <van-list
          v-model:loading="loading"
          v-model:error="error"
          :finished="finished"
          :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
          error-text="请求失败，点击重新加载"
          :immediate-check="false"
          @load="onNext0"
        >
          <goods-card
            v-for="(item, index) of list"
            :key="index"
            :info="item"
            @share="handleShare(item)"
          />
        </van-list>
      </template>
      <template v-if="activeArr[1] === active">
        <van-list
          v-model:loading="loading"
          v-model:error="error1"
          :finished="finished1"
          :finished-text="list1.length == 0 ? '暂无数据' : '没有更多了'"
          error-text="请求失败，点击重新加载"
          :immediate-check="false"
          @load="onNext1"
        >
          <order-card
            v-for="(item, index) of list1"
            :key="index"
            :info="item"
            @share="handleShare(item)"
          />
        </van-list>
      </template>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item
        v-for="(tabber, index) in shareTabbers"
        :icon="tabber.icon"
        :key="index"
        :name="tabber.name"
        @click="tabberChange(index)"
      >
        {{ tabber.name }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem, NavBar, List, ActionSheet } from 'vant'
import { getCustomerAnalysis } from '../../service/customer.service'
import { toRefs, reactive, computed, onMounted, onUpdated } from 'vue'
import { pagination } from '../../utils/pagination'
import { getGoodsList, getOrderList } from '../../service/share.service'
import {
  getCurExternalContact,
  getCurExternalChat,
  sendChatMessageAllType
} from '@/utils/nwechat.js'
import goodsCard from './goodsCard.vue'
import orderCard from './orderCard.vue'
export default {
  name: 'share',
  props: {},
  data() {
    const shareTabbers = [
      {
        name: '商品',
        icon: 'shop-o'
      },
      {
        name: '订单',
        icon: 'balance-list-o'
      }
    ]
    return {
      shareTabbers,
      active: shareTabbers[0].name,
      activeArr: shareTabbers.map((ele) => ele.name),
      shareInfo: {},

      pageSize: 10, // 每页条数
      page: 0, // 页码
      list: [],
      loading: false,
      finished: false,
      error: false,
      page1: 0,
      list1: [],
      finished1: false,
      error1: false,
      userId: '',
      groupId: ''
    }
  },
  computed: {},
  async created() {
    if (this.$route.query && this.$route.query.type) {
      if (this.$route.query.type === 'user') {
        this.userId = await getCurExternalContact()
      } else if (this.$route.query.type === 'group') {
        this.groupId = await getCurExternalChat()
      }
    }
    this.list = []
    this.onNext0()
  },
  mounted() {},
  watch: {},
  methods: {
    async onNext0() {
      this.loading = true
      // console.log('请求=====')
      let page = this.page + 1
      let queryParams = {
        page,
        size: this.pageSize
      }
      let res = await getGoodsList(queryParams)
      this.loading = false
      if (res.list) {
        this.error = false
        this.list = [...this.list, ...res.list]
        if (this.list.length < res.count) {
          this.finished = false
          this.page = page
        } else {
          this.finished = true
        }
      } else {
        this.error = true
      }
    },
    async onNext1() {
      // console.log('请求=====1')
      this.loading = true
      let page = this.page1 + 1
      let query = {}
      if (this.userId) {
        query.external_userid = this.userId
      } else if (this.groupId) {
        query.groupchat_id = this.groupId
      }
      let queryParams = {
        page,
        size: this.pageSize,
        query
      }
      let res = await getOrderList(queryParams)
      this.loading = false
      if (res.list) {
        this.error = false
        this.list1 = [...this.list1, ...res.list]
        if (this.list1.length < res.count) {
          this.finished1 = false
          this.page1 = page
        } else {
          this.finished1 = true
        }
      } else {
        this.error1 = true
      }
    },
    async tabberChange(i) {
      if (i === 0) {
        this.list = []
        this.page = 0
        this.onNext0()
      } else if (i === 1) {
        this.list1 = []
        this.page1 = 0
        this.onNext1()
      }
    },

    // 转发小程序
    async onShareAppMessage(shareObj) {
      let res = await sendChatMessageAllType('miniprogram', {
        miniprogram: { ...shareObj }
      })
      // console.log('发送群店小程序', res)
    },
    handleShare(item) {
      this.shareInfo = item
      let title = ''
      let imgUrl = ''
      let page = ''
      let appid = ''
      if (this.active === '商品') {
        title = `${item.share_title}为您推荐${item.title}`
        imgUrl = 'https:' + item.mini_card_img
        page = this.shareInfo.share_path
        appid =item.app_id
      } else {
        title = `${item.share_title}给你分享了商品订单`
        imgUrl = item.order_item && item.order_item.snap_good_order_item_img
        imgUrl = 'https:' + imgUrl
        let path = item.pay_status === 'WAIT_PAY' ? 'product' : 'order'
        page = `pages/${path}/index.html?orderId=${item.id}&memberId=${item.member_id}&goodId=${item.order_item.good_id}`
        appid =item.app_id

      }
      let shareObj = {
        appid,//小程序的appid
        title,
        imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
        page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
      }
      this.onShareAppMessage(shareObj)
    }
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
    [List.name]: List,
    [goodsCard.name]: goodsCard,
    [ActionSheet.name]: ActionSheet,
    [orderCard.name]: orderCard
  }
}
</script>

<style lang="less" scoped>
.content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0px 6px 56px 6px;
}
.container {
  height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   .content {
  //     flex: 1;
  //     overflow: auto;
  //     padding-top: 46px;
  //     padding-bottom: 50px;
  //   }
}
</style>
